<template>
  <div class="container mescroll-touch">
    <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
    <div class="containerBox" id="toTop">
    <div class="top">
      <img class="menuIcon" src="@/assets/images/menu_comm_icon@2x.png" @click="sidebarShow = true" />
      <div class="searchBox" @click.stop="$router.push('/search')">
        <img class="searchIcon" src="@/assets/images/search_comm_icon@2x.png" />
        <span class="searchInput">请输入关键字搜索</span>
        <!-- <input class="searchInput" type="text" placeholder="请输入关键字搜索" disabled /> -->
      </div>
    </div>
    <div class="classify">
      <div class="classify_content" ref="wrapper">
        <div class="classify_wrapper">
          <span class="active_classify">全部</span>
          <span v-for="(item, index) in navBar" :key="index" @click="toDepartment(item.Code, item.DisplayName)">{{item.DisplayName}}</span>
        </div>
      </div>
      <div class="more">
        <router-link to="/interest">
          <img class="addIcon" src="@/assets/images/add_comm_icon@2x.png" />
          <span>更多科室</span>
        </router-link>
      </div>
    </div>
    <div class="content">
      <div class="banner" v-if="bannerList.length > 0">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#CA001B">
          <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="imgclick(item.UrlLink)">
            <img class="bannerImg" :src="item.File_Url" />
          </van-swipe-item>
        </van-swipe>
      </div>

      <div class="liveBox">
        <div class="screenBox">
          <div class="screenBtn" @click="showScreen">
            <span>科室筛选</span>
            <img src="@/assets/images/screen.png" />
          </div>
          <van-popup v-model="showScreenPopover" round position="bottom" :style="{ height: '50%' }">
            <div class="pickerContent">
              <div class="van-picker__toolbar toolbar">
                <button type="button" class="van-picker__cancel" @click="onScreenCancel">取消</button>
                <button type="button" class="van-picker__confirm" @click="onScreenConfirm">确认</button>
              </div>
              <div class="picker_content">
                <div class="pickerBox">
                  <div class="picker_block" :class="{'activeBg': tempLiveScreenValue == item.Value}" v-for="(item, index) in liveScreenList" :key="index" @click="selectLiveScreen(item.Value)">{{item.Name}}</div>
                </div>
              </div>
            </div>
          </van-popup>
        </div>
        <weekly-calendar bgcolor="#F7F7F7" :calendarCode="liveScreenValue" @activeDate="activeDate" :key="calendarKey"></weekly-calendar>
        <div class="liveWrapper">
          <div class="liveList">
            <div class="liveItem" v-for="item in LiveList" :key="item.Id" @click="toContentPage(item.Id, item.Type)">
              <div class="liveStatu" v-if="item.LiveStatus == 1 || item.LiveStatus == 2">
                <template v-if="item.LiveStatus == 1">
                  <span>正在直播</span>
                  <img class="icon_live" src="@/assets/images/live.png" />
                </template>
                <countdown v-if="item.LiveStatus == 2" :time="item.StartTime"></countdown>
              </div>
              <p class="date">{{ item.StartTime | timeQuantum(item.EndTime) }}</p>
              <p class="liveTitle">{{ item.SimpleTitle }}</p>
              <div class="WatchCount">
                <img src="@/assets/images/watch.png" />
                <span>{{ item.WatchCount }}人</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="live_conference">
        <div class="live_title">
          <div class="live_title_text" @click="liveShow = !liveShow">
            <span>直播会议</span>
            <img :class="{'rotate': liveShow}" src="@/assets/images/open_comm_icon@2x.png" />
          </div>
          <div class="live_more" @click="toLiveList()">
            <span>更多</span>
            <img src="@/assets/images/more_comm_icon@2x.png" />
          </div>
        </div>
        <div class="live_content" v-show="liveShow && LiveList.length > 0">
          <swiper ref="liveSwiper" :options="liveSwiperOptons">
            <swiper-slide v-for="(item, index) in LiveList" :key="index">
              <div @click="toContentPage(item.Id, item.Type)">
                <img class="live_cover" :src="item.Thumbnail" />
                <div class="live_status">
                  <span>{{item.LiveStatus == 1 ? '直播中' : item.LiveStatus == 2 ? '未开始' : item.LiveStatus == 3 ? '已结束' : ''}}</span>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-pagination" slot="pagination"></div>
        </div>
      </div> -->

      <div class="home_classify">
        <div class="home_classify_content">
          <div class="home_classify_item" :class="{'home_classify_item_active': ModuleType == 1}" @click="selectClassify(1)">
            <img class="screenIcon" :src="ModuleType == 1 ? require('@/assets/images/new_home_icon_alt@2x.png') : require('@/assets/images/new_home_icon_nor@2x.png')" />
            <span>最新上线</span>
          </div>
          <div class="home_classify_item" :class="{'home_classify_item_active': ModuleType == 2}" @click="selectClassify(2)">
            <img class="screenIcon" :src="ModuleType == 2 ? require('@/assets/images/good_home_icon_alt@2x.png') : require('@/assets/images/good_home_icon_nor@2x.png')" />
            <span>热门推荐</span>
          </div>
          <div class="home_classify_item" :class="{'home_classify_item_active': ModuleType == 3}" @click="selectClassify(3)">
            <img class="screenIcon" :src="ModuleType == 3 ? require('@/assets/images/persons_home_icon_alt@2x.png') : require('@/assets/images/persons_home_icon_nor@2x.png')" />
            <span>同行在看</span>
          </div>
        </div>
        <img class="screenIcon" @click="showDepartment" src="@/assets/images/screen_home_icon@2x.png" />
        <div class="screenTips" v-if="homeTipsShow">
          <span>点击按钮，选择您想要的科室内容</span>
          <img @click="closeTips" src="@/assets/images/delete_home_icon@2x.png" />
        </div>
        <van-popup v-model="showDepartmentPicker" round position="bottom" :style="{ height: '50%' }">
          <div class="pickerContent">
            <div class="van-picker__toolbar toolbar">
              <button type="button" class="van-picker__cancel" @click="onDepartmentCancel">取消</button>
              <button type="button" class="van-picker__confirm" @click="onDepartmentConfirm">确认</button>
            </div>
            <div class="picker_content">
              <div class="pickerBox">
                <div class="picker_block" :class="{'activeBg': tempDepartmentTCcode == item.TCcode}" v-for="(item, index) in DepartmentColumns" :key="index" @click="selectDepartment(item.TCcode)">{{item.Name}}</div>
              </div>
            </div>
          </div>
        </van-popup>
      </div>
      <div id="dataList">
        <waterfall :col="2" :width="itemWidth" :gutterWidth="gutterWidth" :data="list">
          <template>
            <div class="list" v-for="(item, index) in list" :key="index" @click="toContentPage(item.Id, item.Type)">
              <div class="item_img">
                <img class="item_cover" :src="item.Thumbnail" />
                <img class="playIcon" v-if="item.Type == 1 || item.Type == 3" src="@/assets/images/play_comm_icon@2x.png" />
              </div>
              <div class="item_content">
                <span class="item_title">{{item.Title}}</span>
                <p class="item_time">{{item.yyyymmdd}}</p>
                <div class="item_label">
                  <span v-for="(label, labelIndex) in item.TagListBy" :key="labelIndex">{{label.DisplayName}}</span>
                </div>
                <div class="item_nums">
                  <div class="nums">
                    <img src="@/assets/images/browse_comm_icon@2x.png" />
                    <span>{{item.WatchCount}}</span>
                  </div>
                  <div class="nums">
                    <img src="@/assets/images/like_comm_icon@2x.png" />
                    <span>{{item.LikeCount}}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </waterfall>
      </div>
      <!-- <div class="iconLink" @click="toQuestionnaire">
        <div class="iconBox">
          <img src="@/assets/images/wenjuan.png" />
        </div>
        <span>问卷调研</span>
      </div> -->
    </div>
    </div>
    </mescroll-vue>
    <Sidebar :sidebarShow="sidebarShow" @popupClose="popupClose" />
  </div>
</template>

<script>
import mixin from '../../common/mixin/mixins'
import { mapState, mapMutations } from 'vuex'
import storage from "../../common/utils/storage";
import BScroll from 'better-scroll'
import MescrollVue from 'mescroll.js/mescroll.vue'
import Sidebar from '../../components/sidebar.vue'
import WeeklyCalendar from '../../components/weeklyCalendar.vue'
import Countdown from '../../components/countdown.vue'

export default {
  name: 'Home',
  data() {
    return {
      scroll: null,
      bannerList: [],
      liveShow: true,
      liveSwiperOptons: {
        pagination:{
          el:'.swiper-pagination',
          clickable:true,
        },
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 10
      },
      showDepartmentPicker: false,
      DepartmentColumns: [],
      tempDepartmentTCcode: '',
      ModuleType: 1,
      LiveList: [],
      list: [],
      PageIndex: 0,
      PageSize: 6,
      mescroll: null, // mescroll实例对象
      mescrollDown:{
        use: false,
        isLock: false
      },
      mescrollUp: { // 上拉加载的配置.
        isBounce: false,
			  callback: this.upCallback, // 上拉回调
				page: {
					num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
					size: 6 //每页数据条数,默认10
				},
        noMoreSize: 0,
				htmlNodata: '<p class="upwarp-nodata">-- END --</p>',
        toTop: {
          warpId : 'toTop',
          src: './static/image/top_comm_icon@2x.png' // 回到顶部按钮的图片路径,支持网络图
        },
        empty: {
          // 列表第一页无任何数据时,显示的空提示布局; 需配置warpId才生效;
          warpId: 'dataList', // 父布局的id;
          icon: './static/image/none_comm_png@2x.png', // 图标,支持网络图
          tip: '敬请期待', // 提示
        }
			},
      sidebarShow: false,
      showScreenPopover: false,
      liveScreenList: [],
      tempLiveScreenValue: '',
      liveScreenValue: '',
      weeklyCalendarDate: '',
      calendarKey: ''
    }
  },
  mixins: [mixin],
  components: {
    MescrollVue,
    Sidebar,
    WeeklyCalendar,
    Countdown
  },
  watch: {
    navBar() {
      this.$nextTick(() => {
        this.scroll.refresh();
      })
    },
  },
  computed: {
    ...mapState(['userInfo', 'isLogin', 'homeTipsShow']),
    itemWidth() {
      return (340 * 0.5 * (document.documentElement.clientWidth / 375))
    },
    gutterWidth() {
      return (20 * 0.5 * (document.documentElement.clientWidth / 375))
    }
  },
  created() {
    // this.initBScroll();
    this.getBanner();
    this.getDepartment();
    this.getLiveList();
    this.getScreen();
    this.$store.commit('changeNavbarIndex', 0);
  },
  mounted() {
    this.initBScroll();
  },
  activated(){
    this.mescroll.setBounce(false);
    this.mescroll.scrollTo(this.mescroll.preScrollY, 0);
    this.$store.commit('changeNavbarIndex', 0);
    this.showScreenPopover = false;
  },
  deactivated() {
    this.mescroll.setBounce(true);
  },
  methods: {
    ...mapMutations(['changeHomeTipsShow']),
    // 初始化BScroll
    initBScroll() {
      this.$nextTick(() => {
        this.scroll = new BScroll(this.$refs['wrapper'], {
          startX: 0,
          click: true,
          scrollX: true,
          scrollY: false,
          eventPassthrough: 'vertical'
        })
      })
    },
    // mescroll组件初始化的回调,可获取到mescroll对象
		mescrollInit (mescroll) {
			this.mescroll = mescroll  // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
		},
    // 上拉回调
		upCallback (page) {
      this.getList(page.num, page.size);
		},
    closeTips() {
      this.changeHomeTipsShow(false);
    },
    // 获取Banner
    getBanner() {
      this.$axios.get('/Api/api/Web/ImgFile/GetImgFileListByCode?Code=HomePage').then(res => {
        this.bannerList = res.Data;
      })
    },
    // 获取直播筛选项
    getScreen() {
      this.$axios.post('/Api/Api/Web/Article/GetScreen', {
        state: "",
        end: ""
      }).then((res) => {
        this.liveScreenList = res.Data.sird;
      })
    },
    // 获取科室
    getDepartment() {
      this.$axios.post('/Api/Api/Web/GetWxUser/GetDitWebCode?type=1&pcode=').then(res => {
        this.DepartmentColumns = res.Data;
      })
    },
    showScreen() {
      this.showScreenPopover = true;
      this.tempLiveScreenValue = this.liveScreenValue;
    },
    // 直播筛选取消选择科室
    onScreenCancel() {
      this.showScreenPopover = false;
      this.tempLiveScreenValue = '';
    },
    // 直播筛选确认选择科室
    onScreenConfirm() {
      this.showScreenPopover = false;
      this.liveScreenValue = this.tempLiveScreenValue;
      this.calendarKey = new Date().getTime();
      this.getLiveList();
    },
    // 显示科室选中picker框
    showDepartment() {
      let Token = storage.getStore("Token");
      if (!this.isLogin) {
        this.$dialog.confirm({
          title: '提示',
          message: '您当前还未注册，请先去注册',
        }).then(() => {
          this.$router.push('/entrance');
        })
      } else if (!Token) {
        this.$dialog.alert({
          title: '提示',
          message: '您当前还未登录，请先去登录',
        }).then(() => {
          this.$router.push('/entrance');
        })
      } else {
        this.showDepartmentPicker = true;
        this.tempDepartmentTCcode = this.DepartmentTCcode;
      }
    },
    // 取消选择科室
    onDepartmentCancel() {
      this.showDepartmentPicker = false;
      this.tempDepartmentTCcode = '';
    },
    // 确认选择科室
    onDepartmentConfirm() {
      this.showDepartmentPicker = false;
      this.DepartmentTCcode = this.tempDepartmentTCcode;
      this.list = [];
      this.mescroll.resetUpScroll() // 刷新列表数据
    },
    // 选择科室
    selectDepartment(TCcode) {
      if(this.tempDepartmentTCcode == TCcode) {
        this.tempDepartmentTCcode = '';
      } else {
        this.tempDepartmentTCcode = TCcode;
      }
    },
    // 获取列表数据
    getLiveList() {
      this.$axios.post('/Api/Api/Web/Article/GetLiveArticleList', {
        time: this.weeklyCalendarDate,
        code: this.liveScreenValue
      }).then(res => {
        this.LiveList = res.Data;
      })
    },
    // 获取列表数据
    getList(PageIndex, PageSize) {
      this.PageIndex = PageIndex;
      this.PageSize = PageSize;
      setTimeout(() => {
        this.$axios.post('/Api/Api/Web/Article/GetArticleList', {
          SubjectTCode: this.DepartmentTCcode,
          ModuleType: this.ModuleType,
          PageIndex: this.PageIndex,
          PageSize: this.PageSize
        }).then((res) => {
          let arr = res.Data.List;
          // 如果是第一页需手动置空列表
          if (PageIndex === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr);
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            this.mescroll.endSuccess(arr.length)
          })
        }).catch((e) => {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          this.mescroll.endErr()
        })
      }, 200);
    },
    // 加载对应分类下的list
    selectClassify(type) {
      let Token = storage.getStore("Token");
      if (type == 3) {
        if (!this.isLogin) {
          this.$dialog.confirm({
            title: '提示',
            message: '您当前还未注册，请先去注册',
          }).then(() => {
            this.$router.push('/entrance');
          })
        } else if (!Token) {
          this.$dialog.alert({
            title: '提示',
            message: '您当前还未登录，请先去登录',
          }).then(() => {
            this.$router.push('/entrance');
          })
        } else {
          this.ModuleType = type;
          this.list = [];
          this.mescroll.resetUpScroll() // 刷新列表数据
        }
      } else {
        this.ModuleType = type;
        this.list = [];
        this.mescroll.resetUpScroll() // 刷新列表数据
      }
    },
    popupClose(val) {
      this.sidebarShow = val;
    },
    toContentPage(Id, Type) {
      this.$router.push({
        path: 'contentPage',
        query: {
          id: Id,
          type: Type
        }
      });
    },
    toLiveList() {
      this.BuriedPoint(1, '直播会议列表', '', '/liveList');
      this.$router.push('/liveList');
    },
    imgclick(UrlLink) {
      let objExp=new RegExp(/^(f|ht)tps?:\/\//i);
      if(UrlLink) {
				if(objExp.test(UrlLink)) {
				  location.href = UrlLink;
				}else{
					this.$router.push(UrlLink)
				}
      }
    },
    selectLiveScreen(val) {
      if(this.tempLiveScreenValue == val) {
        this.tempLiveScreenValue = '';
      } else {
        this.tempLiveScreenValue = val;
      }
    },
    activeDate(date) {
      this.weeklyCalendarDate = date;
      this.getLiveList();
    },
    toQuestionnaire() {
      // window.location.href = 'https://www.wjx.cn/vm/etNd2Om.aspx#';
      this.$router.push('/SSIquestionnaire');
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100%;
  // padding: 16px 0 56px;
  box-sizing: border-box;
  background-color: #F9F9F9;
  .containerBox {
    background-image: url('../../assets/images/bg_comm_png@2x.png');
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 16px;
  }
  .mescroll {
    position: fixed;
    top: 0;
    bottom: 60px;
    height: auto;
  }
  .top {
    margin-bottom: 10px;
    display: flex;
    padding: 0 12px;
    .menuIcon {
      width: 30px;
      height: 30px;
    }
    .searchBox {
      display: flex;
      align-items: center;
      flex: 1;
      margin-left: 10px;
      height: 30px;
      line-height: 30px;
      border-radius: 30px;
      background-color: rgba(255, 255, 255, 0.9);
      padding: 0 15px;
      .searchIcon {
        width: 14px;
        height: 14px;
      }
      .searchInput {
        flex: 1;
        margin-left: 4px;
        border: none;
        background-color: transparent;
        color: #999999;
      }
    }
  }
  .classify {
    display: flex;
    color: #FFFFFF;
    height: 31px;
    padding: 0 12px;
    .classify_content {
      display: flex;
      flex: 1;
      font-size: 14px;
      overflow: hidden;
      .classify_wrapper {
        white-space: nowrap;
        span {
          position: relative;
        }
        span:not(:last-child) {
          margin-right: 20px;
        }
        .active_classify {
          font-size: 18px;
          font-weight: bold;
        }
        .active_classify::after {
          content: "";
          width: 22px;
          height: 3px;
          border-radius: 3px;
          background-color: #FFFFFF;
          position: absolute;
          top: 25px;
          left: 50%;
          transform: translateX(-11px);
        }
      }
    }
    .more {
      font-size: 12px;
      margin-left: 10px;
      a {
        display: flex;
        align-items: center;
        color: #FFFFFF;
        margin-top: 6px;
      }
      .addIcon {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
      span {
        opacity: 0.8;
      }
    }
  }
  .content {
    min-height: 100%;
    margin-top: 1px;
    padding: 0 12px;
    background-color: #F9F9F9;
    border-radius: 12px 12px 0 0;
  }
  .content:before{
    content: '';
    display: table;
  }
  .banner {
    // height: 185px;
    margin: 20px 0 15px 0;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px 0px;
    overflow: hidden;
    .bannerImg {
      width: 100%;
      display: block;
    }
  }
  .live_conference {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 15px;
    margin-top: 15px;
    .live_title {
      display: flex;
      justify-content: space-between;
      color: #CA001B;
      .live_title_text {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: bold;
        img {
          width: 12px;
          height: 6px;
          margin-left: 7px;
        }
        .rotate {
          transform: rotate(180deg);
        }
      }
      .live_more {
        display: flex;
        align-items: center;
        font-size: 12px;
        img {
          width: 12px;
          height: 12px;
          margin-left: 4px;
        }
      }
    }
    .live_content {
      position: relative;
      // height: 90px;
      margin-top: 10px;
      overflow: hidden;
      .live_cover {
        width: 100%;
        // height: 76px;
      }
      .live_status {
        position: absolute;
        top: 5px;
        left: 5px;
        display: inline-block;
        height: 15px;
        line-height: 15px;
        border-radius: 15px;
        padding: 0 6px;
        font-size: 9px;
        color: #FFFFFF;
        background-color: #000000;
        opacity: 0.5;
      }
    }
  }
  .liveBox {
    position: relative;
    box-sizing: border-box;
    padding: 12px;
    background-color: #FFFFFF;
    border-radius: 10px;
    margin-bottom: 15px;
    .screenBox {
      position: absolute;
      top: 12px;
      right: 12px;
    }
    .screenBtn {
      font-size: 12px;
      color: #CA001B;
      img {
        width: 12px;
        height: 12px;
        vertical-align: middle;
        margin-left: 3px;
      }
    }
    .liveWrapper {
      display: flex;
      overflow-x: scroll;
      margin-top: 12px;
      .liveList {
        display: flex;
        flex-wrap: nowrap;
        .liveItem {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          width: 300px;
          height: 84px;
          border: 1px solid #DDDDDD;
          border-radius: 10px;
          padding: 4px 12px;
          box-sizing: border-box;
          overflow: hidden;
          .liveStatu {
            display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            right: 0;
            font-size: 11px;
            color: #CA001B;
            padding: 2px 8px;
            background-color: #F9E5E8;
            border-radius: 0 10px 0 10px;
            .icon_live {
              width: 12px;
              height: 12px;
              margin-left: 6px;
            }
          }
          .date {
            font-size: 12px;
            color: #999999;
          }
          .liveTitle {
            font-size: 15px;
            color: #333333;
            font-weight: bold;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
          }
          .WatchCount {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #999999;
            img {
              width: 12px;
              margin-right: 2px;
            }
          }
        }
        .liveItem:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
    .liveWrapper::-webkit-scrollbar { display: none; }
  }
  .home_classify {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .home_classify_content {
      display: flex;
      .home_classify_item {
        display: flex;
        align-items: center;
        height: 30px;
        border-radius: 30px;
        padding: 0 10px;
        margin-right: 10px;
        box-sizing: border-box;
        font-size: 12px;
        color: #CA001B;
        border:  1px solid rgba(202, 0, 27, 0.5);
        .screenIcon {
          width: 16px;
          height: 14px;
          margin-right: 4px;
        }
      }
      .home_classify_item_active {
        color: #FFFFFF;
        background-color: #CA001B;
        border: none;
      }
    }
    .screenIcon {
      width: 30px;
      height: 30px;
    }
    .screenTips {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      right: -2px;
      bottom: 40px;
      z-index: 99;
      width: 66%;
      height: 30px;
      line-height: 30px;
      border-radius: 10px;
      padding: 0 15px;
      box-sizing: border-box;
      background: rgba(0, 0, 0, 0.75);
      font-size: 12px;
      color: #FFFFFF;
      img {
        width: 10px;
        height: 10px;
      }
    }
    .screenTips:before{
      content: "";
      width: 0px;
      height: 0px;
      border-top: 8px solid rgba(0, 0, 0, 0.75);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      position: absolute;
      top: 30px;
      right: 9px;
    }
  }
  .picker_content {
    padding: 20px 6px;
    .pickerBox {
      display: flex;
      flex-wrap: wrap;
    }
    .picker_block {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(calc(100% / 3) - 12px);
      height: 36px;
      text-align: center;
      border: 1px solid rgba(153, 153, 153, 0.5);
      box-sizing: border-box;
      margin: 6px;
      border-radius: 4px;
      color: #333333;
      font-size: 14px;
    }
    .activeBg {
      border: none;
      color: #C70C3C;
      background-image: url('../../assets/images/select_system_btn@2x.png');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .list {
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 10px;
    .item_img {
      position: relative;
      .item_cover {
        display: block;
        width: 100%;
      }
      .playIcon {
        position: absolute;
        right: 12px;
        bottom: 12px;
        width: 30px;
        height: 30px;
      }
    }
    .item_content {
      padding: 5px 8px 8px;
      .item_title {
        height: 38px;
        font-size: 14px;
        color: #333333;
        overflow: hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
        word-wrap: break-word;
      }
      .item_time {
        font-size: 12px;
        color: #999999;
        line-height: 24px;
      }
      .item_label {
        display: flex;
        flex-wrap: wrap;
        span {
          display: inline-block;
          height: 20px;
          line-height: 20px;
          border-radius: 2px;
          padding: 0 6px;
          margin-right: 6px;
          margin-bottom: 5px;
          background-color: rgba(255, 73, 98, 0.15);
          font-size: 11px;
          color: #CA001B;
        }
      }
      .item_nums {
        display: flex;
        justify-content: flex-end;
        .nums {
          display: flex;
          align-items: center;
          img {
            width: 12px;
            margin: 0 2px 0 6px;
          }
          span {
            font-size: 11px;
            color: #666666;
          }
        }
      }
    }
  }
}

.screenItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 132px;
  height: 32px;
  // line-height: 32px;
  font-size: 13px;
  color: #333333;
  padding: 0 12px;
  img {
    width: 12px;
    height: 12px;
  }
}
.liveScreenActive {
  color: #CA001B;
}
.iconLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 120px;
  right: 8px;
  .iconBox {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 3px;
  }
  img {
    width: 40px;
    height: 40px;
  }
  span {
    font-size: 10px;
    color: #CA001B;
  }
}

/deep/ .swiper-pagination-bullet-active {
  background-color: #CA001B !important;
}

/deep/ .swiper-pagination-bullet {
  width: 10px;
  height: 3px;
  border-radius: 3px;
  background-color: #999999;
  margin: 0;
}

/deep/ .swiper-pagination-bullets {
  bottom: 0px;
}

/deep/ .van-swipe__indicators {
  top: 90%;
}

/deep/ .van-popover--light {
  height: 100px !important;
  overflow: scroll !important;
}
</style>